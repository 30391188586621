@import "../helpers/mixins";

.flex-column {
    @include flexbox();
    @include flex-direction(column);
    @include flex-wrap(nowrap);
}

.flexbox {
    @include flexbox();
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include justify-content(flex-start);
    @include align-items(flex-start);
}

.direction-col {
    @include flex-direction(column);
}

.all-center {
    @include justify-content(center);
    @include align-items(center);
}

.justify-center {
    @include justify-content(center);
}

.justify-between {
    @include justify-content(space-between);
}

.justify-around {
    @include justify-content(space-around);
}

.justify-start {
    @include justify-content(flex-start);
}

.justify-end {
    @include justify-content(flex-end);
}

.item-center {
    @include align-items(center);
}

.item-start {
    @include align-items(flex-start);
}

.item-end {
    @include align-items(flex-end);
}

.item-stretch {
    @include align-items(stretch);
}

.item-wrap {
    @include flex-wrap(wrap);
}