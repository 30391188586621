@import '../../sass/main.scss';
.main-wrapper {
  .ant-layout-sider {
    .ant-menu-item-selected {
      background-color: #f7cca6;
      border-right: 1px solid #de893f;
      color: black;
    }
    &:after {
      border: none;
    }
    background-color: white;
  }
  .ant-layout-sider-trigger {
    border-top: 1px solid #f7cca6;
    background-color: white;
    color: rgb(0, 0, 0);
  }
  .ant-menu-inline .ant-menu-item::after {
    border: none;
  }
  @include animation('fadeIn 0.8s');

  @include keyframes(fadeIn) {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .main-header {
    margin-bottom: 20px;
    background-color: white;
    max-height: 9vh;
    min-height: 9vh;
    @extend .flexbox;
    @extend .item-center;
    min-height: 60px;
    @include transition(all 0.8s ease-in-out);
    &__brand {
      flex: 0 auto;
      font-size: 16px;
      cursor: pointer;
      color: white;
      width: 50px;
      height: 20px;
      margin-top: -25px;

      a {
        display: block;

        span {
          display: block;
          min-width: 160px;
        }
      }
    }
    &__title {
      width: 100%;
      text-align: center;
      h2 {
        color: #de893f;
        font-size: 30px;
        line-height: 1;
        display: none;
      }
    }
    &__menu {
      flex: 0 0 auto;
      margin-left: auto;
      margin-right: 15px;
      @extend .flexbox;
      @extend .item-center;

      &--list {
        @extend .flexbox;
        @extend .item-center;
        margin: 0;
        li {
          flex: 0 auto;
          &:last-child {
            margin-left: 20px;
          }

          a {
            display: block;
            padding: 15px 10px;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
            position: relative;
            color: #7d8b97;
            font-weight: 600;
            text-decoration: none;

            &:hover,
            &.active {
              margin: 0;
              color: $orang-color;
            }
          }

          .dropdown {
            min-width: auto !important;

            .btn {
              width: 35px;
              height: 35px;
              overflow: hidden;
              margin: 0;
              padding: 0;
              border: 1px solid transparent;
              background-color: $white-color;
              @extend .flexbox;
              @extend .all-center;
              @include border-radius(50%);

              img {
                width: 100%;
              }

              &:after {
                border: 0px;
                width: 0px;
                height: 0px;
                margin: 0px;
              }
            }

            .dropdown-menu {
              left: auto !important;
              right: 0px !important;
              margin-top: 13px;
              padding: 0;
              @include border-radius(3px);
              @include transform(translate(0, 0) !important);

              .dropdown-item {
                @extend .flexbox;
                @extend .item-center;
                padding: 8px 10px !important;
                color: #787878;

                i {
                  flex: 0 auto;
                  margin-right: 6px;
                }

                span {
                  flex: 1 auto;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }

  .main-content {
    padding: 20px 0;

    .card {
      min-height: 100%;
    }
  }
}
