.btn {
    cursor: pointer;
    overflow: hidden;
    font-size: 13px;
    text-transform: uppercase;
    @extend .flexbox;
    @extend .all-center;
    @include border-radius(2px);
    @include transition (all 0.8s ease-in-out);

    &:focus,
    &:active,
    &:hover,
    &:visited,
    &:focus-within {
        @include box-shadow(0, 0, 0, 0 !important);
        @include transition (all 0.8s ease-in-out);
    }

    &.btn-primary {
        color: $white-color;
        border: 0px;
        background-size: 200%;
        @include linear-gradient(to right, ($gradient-one));
        @include transition (all 0.8s ease-in-out);

        &:focus,
        &:active,
        &:hover,
        &:visited,
        &:focus-within {
            background-position: 100%;
            @include transition (all 0.8s ease-in-out);
        }
    }

    i {
        flex: 0 auto;
        font-size: 16px;
        margin-right: 8px;
    }

    span {
        flex: 0 auto;
    }

    &.icon {
        padding: 0;
        border: 0 !important;
        background: transparent !important;

        i {
            color: $text-color;
        }

        &:hover {
            background: transparent;
        }
    }


}

.form-control {
    display: block;
    width: 100%;
    @include border-radius(4px);
    @include box-shadow(0, 0, 0, transparent);
    @include transition (all 0.3s ease-in-out);
    font-size: 13px;
    padding: 0 15px;
    line-height: 34px;
    overflow: hidden;
    border: #e7e6e6 solid 1px;

    &:focus,
    &:hover,
    &:active {
        outline: 0;
        @include box-shadow(0, 0, 0, transparent);
        @include transition (all 0.3s ease-in-out);
    }
}

.alert {
    margin-bottom: 0px;
}

.card {
    @include border-radius(2px);
    border-color: $border-color;
    &-header {
        @extend .flexbox;
        @extend .item-center;
        padding: 10px;
        background-color: $white-color;

        .controls {
            margin-left: auto;
        }

        h3 {
            font-size: 16px;
            margin: 0;
            text-transform: capitalize;
        }
    }

    &-body {
        margin: 0;
        padding: 10px;

        h2 {
            margin-bottom: 10px;
        }
    }

    &-footer {
        @extend .flexbox;
        padding: 10px;

        button {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.ant-modal-header {
    padding: 20px;
}

.ant-select {
    flex: 1 auto;

    .ant-select-selection {
        @include border-radius(0);
        @include box-shadow(0, 0, 0, transparent);
        @include transition (all 0.3s ease-in-out);
        font-size: 13px;
        display: block;
        padding: 0;
        height: 30px;
        overflow: hidden;
        border-color: #d3d3d3;
    }
}

.dropdown-menu {
    padding: 0;
    border: 0;
    width: auto !important;
    top: 100% !important;
    @include border-radius(2px);
    @include box-shadow(0, 1px, 6px, rgba(0, 0, 0, 0.3));
    @include transition(all 0.3s ease-in-out);
    @include transform(translate(0, 20px) !important);

    &.show {
        @include transform(translate(0, 0px) !important);
    }

    &__item {
        font-size: 13px;
        padding: 6px 10px;
        width: auto !important;

        &.active {
            @include linear-gradient(to right, ($gradient-one));
        }
    }
}

.breadcrumb {
    @extend .flexbox;
    @extend .item-center;

    .breadcrumb-item {
        flex: 0 0 auto;

        a {
            font-size: 13px;
        }

        &.active {
            margin: 0;

            span {
                font-size: 13px;
                font-weight: 600;
            }
        }
    }
}

.ant-table {
    margin: 0;

    &-header,
    &-body {
        margin: 0;

        tr {
            margin: 0;

            td {
                vertical-align: middle;
            }
        }
    }
}

.ant-pagination {
    @extend .flexbox;
    @extend .item-center;

    .ant-pagination-disabled,
    .ant-pagination-next,
    .ant-pagination-prev,
    .ant-pagination-item {
        flex: 0 0 auto;
        margin-right: 4px;
        max-width: 26px;
        min-width: 36px;
        max-height: 26px;
        min-height: 26px;
        border: solid 1px olivedrab;
        @extend .flexbox;
        @extend .all-center;
        @include border-radius(2px);
        @include transition(all 0.8s ease-in-out);

        a,
        .ant-pagination-item-link {
            flex: 0 0 auto;
            line-height: 8px !important;
            border: 0;
            height: auto;
            background-color: transparent;
        }

        &.ant-pagination-item-active {
            border-color:$orang-color;
            background-color: $orang-color;
            color: $white-color;
            font-weight: 700;
            @include transition(all 0.8s ease-in-out);
        }
    }

    .ant-pagination-item,
    .ant-pagination-next,
    .ant-pagination-prev {
        border: solid 1px #c3c3c3;
        @include transition(all 0.8s ease-in-out);

        &:hover {
            border-color:$orang-color;
            @include transition(all 0.8s ease-in-out);

            a {
                color:$orang-color;
                @include transition(all 0.8s ease-in-out);
            }
        }
    }

    .ant-pagination-disabled {
        border: solid 1px #d7d7d7;
        background-color: #eeeeee;
    }
    .ant-pagination-total-text,
    .ant-pagination-options {

        width: auto !important;

        .ant-select {
            margin: 0px !important;
        }
    }

    .ant-pagination-total-text {
        margin-right: auto;
    }
}


// .row {
//     margin: 0px -5px;

//     [class^="col-"],
//     [class*="col-"] {
//         padding: 0px 5px;
//     }
// }

.card {
    border: 0;
    @extend .flex-column;
    @include border-radius(4px);
    @include box-shadow(0, 0, 0, 0, rgba(255, 255, 255, 0));

    &-header {
        @extend .flexbox;
        @extend .item-center;
        padding: 10px;
        background-color: transparent;
        border-color: $border;

    }

    &-body {
        padding: 10px;

        h2 {
            margin-bottom: 10px;
        }
    }

    &-footer {
        @extend .flexbox;
        padding: 10px;
        ;
    }
}

/*== ant-d override ==*/
// .ant-pagination {
//     @extend .flexbox;
//     @extend .item-center;
//     @extend .justify-end;

//     li {
//         flex: 0 0 auto;
//         border: 0;
//         padding: 0px;
//         height: 30px;
//         margin-right: 8px;
//         @include border-radius(0);

//         a {
//             flex: 0 0 auto;
//             height: 30px;
//             line-height: 30px;
//             font-size: 13px;
//             margin: 0px;
//             padding: 0px 8px;
//             border: solid 1px #d9d9d9;
//             @extend .flexbox;
//             @extend .all-center;
//             @include border-radius(3px);
//             @include transition(all 0.8s ease-in-out);

//         }

//         &.ant-pagination-jump-next {
//             flex: 0 0 auto;

//             a {
//                 border: 0px;
//                 padding: 0px;

//                 .ant-pagination-item-container {
//                     @extend .flexbox;
//                     @extend .all-center;
//                     width: 100%;
//                     height: 30px;

//                     .anticon {
//                         color: $header;
//                     }

//                     .ant-pagination-item-ellipsis {
//                         color: $header;
//                     }
//                 }
//             }
//         }

//         &.ant-pagination-item-active {
//             flex: 0 0 auto;

//             a {
//                 background-color: $header;
//                 border-color: $header;
//                 color: $white !important;

//                 &:hover {
//                     color: $white !important;

//                 }
//             }
//         }

        // &.ant-pagination-prev,
        // &.ant-pagination-next {
        //     flex: 0 0 auto;

        //     a {
        //         border: 0px;
        //     }
        // }

        // &.ant-pagination-total-text,
        // &.ant-pagination-options {

        //     width: auto !important;

        //     .ant-select {
        //         margin: 0px !important;
        //     }
        // }

        // &.ant-pagination-total-text {
        //     margin-right: auto;
        // }

        // .ant-select-selection--single {
        //     height: 30px;
        //     @include border-radius(3px);

        //     .ant-select-selection-selected-value {
        //         line-height: 30px;
        //     }
        // }
    // }
// }