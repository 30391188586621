/*---------Color-----------*/
/*---------Color-----------*/

$white-color: #ffffff;
$black-color: #000000;
$text-color:#737373;
$red-color:#CC0000;
$dark-gray:#3f3f3f;
$border-color: #edeef1;
$gray-color:#f2f2f2;
$yello-color:#ffc000;
$orang-color:#ed7d31;
$gradient-one:($orang-color, $yello-color);

$bg:#f6f6f6;
$white: #ffffff;
$red:#da0303;
$black: #000000;
$light-gray:#fbfbfb;
$gray:#f8f8f8;
$footer:#f0f0f0;
$dark-gray:#9e9e9e;
$text:#26303b;
$highlights:#fcf3c0;
$border:#e2e2e2;
$header:#ed7d31;
/*---------Font-----------*/