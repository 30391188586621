@import url(https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
/* 
factweavers.com Reset Stylesheet
v2.0.1
Last Updated: 2018-November
Author: Jagadeeshlal - jagadeeshlal@factweavers.com 
*/
/*---------Color-----------*/
/*---------Color-----------*/
/*---------Font-----------*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: 0;
  vertical-align: baseline; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav,
ul,
li,
ol {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .card {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  flex-wrap: nowrap; }

.flexbox, .btn, .card-header, .card-footer, .breadcrumb, .ant-pagination, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.direction-col {
  -moz-flex-direction: column;
  flex-direction: column; }

.all-center, .btn, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  -moz-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .card-header, .breadcrumb, .ant-pagination {
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -moz-align-items: stretch;
  align-items: stretch; }

.item-wrap {
  flex-wrap: wrap; }

.btn {
  cursor: pointer;
  overflow: hidden;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .btn:focus, .btn:active, .btn:hover, .btn:visited, .btn:focus-within {
    box-shadow: 0 0 0 0 !important;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
  .btn.btn-primary {
    color: #ffffff;
    border: 0px;
    background-size: 200%;
    background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
    background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
    background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
    background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
    background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
    background-image: linear-gradient(to right, #ed7d31, #ffc000);
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:hover, .btn.btn-primary:visited, .btn.btn-primary:focus-within {
      background-position: 100%;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }
  .btn i {
    flex: 0 1 auto;
    font-size: 16px;
    margin-right: 8px; }
  .btn span {
    flex: 0 1 auto; }
  .btn.icon {
    padding: 0;
    border: 0 !important;
    background: transparent !important; }
    .btn.icon i {
      color: #737373; }
    .btn.icon:hover {
      background: transparent; }

.form-control {
  display: block;
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  padding: 0 15px;
  line-height: 34px;
  overflow: hidden;
  border: #e7e6e6 solid 1px; }
  .form-control:focus, .form-control:hover, .form-control:active {
    outline: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.alert {
  margin-bottom: 0px; }

.card {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-color: #edeef1; }
  .card-header {
    padding: 10px;
    background-color: #ffffff; }
    .card-header .controls {
      margin-left: auto; }
    .card-header h3 {
      font-size: 16px;
      margin: 0;
      text-transform: capitalize; }
  .card-body {
    margin: 0;
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }
    .card-footer button {
      margin-right: 15px; }
      .card-footer button:last-child {
        margin-right: 0; }

.ant-modal-header {
  padding: 20px; }

.ant-select {
  flex: 1 1 auto; }
  .ant-select .ant-select-selection {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    display: block;
    padding: 0;
    height: 30px;
    overflow: hidden;
    border-color: #d3d3d3; }

.dropdown-menu {
  padding: 0;
  border: 0;
  width: auto !important;
  top: 100% !important;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(0, 20px) !important;
  transform: translate(0, 20px) !important; }
  .dropdown-menu.show {
    -webkit-transform: translate(0, 0px) !important;
    transform: translate(0, 0px) !important; }
  .dropdown-menu__item {
    font-size: 13px;
    padding: 6px 10px;
    width: auto !important; }
    .dropdown-menu__item.active {
      background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
      background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
      background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
      background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
      background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
      background-image: linear-gradient(to right, #ed7d31, #ffc000); }

.breadcrumb .breadcrumb-item {
  flex: 0 0 auto; }
  .breadcrumb .breadcrumb-item a {
    font-size: 13px; }
  .breadcrumb .breadcrumb-item.active {
    margin: 0; }
    .breadcrumb .breadcrumb-item.active span {
      font-size: 13px;
      font-weight: 600; }

.ant-table {
  margin: 0; }
  .ant-table-header, .ant-table-body {
    margin: 0; }
    .ant-table-header tr, .ant-table-body tr {
      margin: 0; }
      .ant-table-header tr td, .ant-table-body tr td {
        vertical-align: middle; }

.ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  flex: 0 0 auto;
  margin-right: 4px;
  max-width: 26px;
  min-width: 36px;
  max-height: 26px;
  min-height: 26px;
  border: solid 1px olivedrab;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-disabled a,
  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next a,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination .ant-pagination-prev a,
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-item a,
  .ant-pagination .ant-pagination-item .ant-pagination-item-link {
    flex: 0 0 auto;
    line-height: 8px !important;
    border: 0;
    height: auto;
    background-color: transparent; }
  .ant-pagination .ant-pagination-disabled.ant-pagination-item-active,
  .ant-pagination .ant-pagination-next.ant-pagination-item-active,
  .ant-pagination .ant-pagination-prev.ant-pagination-item-active,
  .ant-pagination .ant-pagination-item.ant-pagination-item-active {
    border-color: #ed7d31;
    background-color: #ed7d31;
    color: #ffffff;
    font-weight: 700;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  border: solid 1px #c3c3c3;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-item:hover,
  .ant-pagination .ant-pagination-next:hover,
  .ant-pagination .ant-pagination-prev:hover {
    border-color: #ed7d31;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .ant-pagination .ant-pagination-item:hover a,
    .ant-pagination .ant-pagination-next:hover a,
    .ant-pagination .ant-pagination-prev:hover a {
      color: #ed7d31;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-disabled {
  border: solid 1px #d7d7d7;
  background-color: #eeeeee; }

.ant-pagination .ant-pagination-total-text,
.ant-pagination .ant-pagination-options {
  width: auto !important; }
  .ant-pagination .ant-pagination-total-text .ant-select,
  .ant-pagination .ant-pagination-options .ant-select {
    margin: 0px !important; }

.ant-pagination .ant-pagination-total-text {
  margin-right: auto; }

.card {
  border: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: inset 0 0 0 0; }
  .card-header {
    padding: 10px;
    background-color: transparent;
    border-color: #e2e2e2; }
  .card-body {
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }

/*== ant-d override ==*/
* {
  margin: 0;
  padding: 0; }

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset; }

button,
input,
textarea {
  box-shadow: 0 0 0 rgba(255, 255, 255, 0); }
  button:hover, button:visited, button:focus, button:active,
  input:hover,
  input:visited,
  input:focus,
  input:active,
  textarea:hover,
  textarea:visited,
  textarea:focus,
  textarea:active {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    outline: 0; }

img {
  border: 0;
  outline: 0;
  max-width: 100%;
  display: inline-block; }

a {
  text-decoration: none; }
  a:hover, a:visited, a:focus, a:active {
    text-decoration: none; }

h1,
h2,
h3,
h4 {
  font-family: "Archivo", sans-serif;
  font-weight: 600; }

em {
  font-weight: 600;
  font-style: normal;
  background-color: #fcf3c0; }

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background: #f6f6f6;
  color: #26303b;
  line-height: 1.428;
  font-weight: 400;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body.slider-lock {
    overflow: hidden; }
    body.slider-lock:before {
      content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 99;
      background-color: rgba(0, 0, 0, 0.6);
      -webkit-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out; }

.keyword__table .ant-table-row {
  cursor: pointer; }

.topic-relation-graph {
  margin-bottom: 15px; }

.topics__card_header {
  display: flex;
  min-width: 100%;
  align-items: flex-start;
  justify-content: space-around; }
  .topics__card_header h2 {
    font-size: 25px;
    color: #2c1f1f;
    font-weight: 400; }
  .topics__card_header * {
    flex: 1 1; }
  .topics__card_header .ant-input-number,
  .topics__card_header .dropdown {
    max-width: 70px;
    border-radius: 4px;
    height: 32px; }
  .topics__card_header .dropdown {
    margin-top: 1px;
    margin-right: 20px;
    max-width: 200px;
    border-radius: 5px; }

.tooltip-wrapper {
  font-size: 12px;
  background-color: black;
  opacity: 0.8;
  max-width: 300px;
  min-width: 200px;
  color: white;
  padding: 10px; }
  .tooltip-wrapper p {
    margin-bottom: 0px; }

.tooltip-body--table {
  width: 100%; }

.tooltip-footer,
.tooltip-body {
  width: 100%;
  margin-top: 12px; }

.tooltip-title :first-child,
.tooltip-footer :first-child {
  font-weight: bold;
  font-size: 14px; }

.tooltip-wrapper:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 20px;
  border-style: solid;
  border-width: 10px 0px 0px 10px;
  border-color: black transparent;
  display: block;
  width: 0;
  z-index: 1; }

/*---------Color-----------*/
/*---------Color-----------*/
/*---------Font-----------*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: 0;
  vertical-align: baseline; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav,
ul,
li,
ol {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .card {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  flex-wrap: nowrap; }

.flexbox, .btn, .card-header, .card-footer, .breadcrumb, .ant-pagination, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item, .sentiment {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.direction-col {
  -moz-flex-direction: column;
  flex-direction: column; }

.all-center, .btn, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  -moz-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .card-header, .breadcrumb, .ant-pagination {
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch, .sentiment {
  -moz-align-items: stretch;
  align-items: stretch; }

.item-wrap {
  flex-wrap: wrap; }

.btn {
  cursor: pointer;
  overflow: hidden;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .btn:focus, .btn:active, .btn:hover, .btn:visited, .btn:focus-within {
    box-shadow: 0 0 0 0 !important;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
  .btn.btn-primary {
    color: #ffffff;
    border: 0px;
    background-size: 200%;
    background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
    background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
    background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
    background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
    background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
    background-image: linear-gradient(to right, #ed7d31, #ffc000);
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:hover, .btn.btn-primary:visited, .btn.btn-primary:focus-within {
      background-position: 100%;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }
  .btn i {
    flex: 0 1 auto;
    font-size: 16px;
    margin-right: 8px; }
  .btn span {
    flex: 0 1 auto; }
  .btn.icon {
    padding: 0;
    border: 0 !important;
    background: transparent !important; }
    .btn.icon i {
      color: #737373; }
    .btn.icon:hover {
      background: transparent; }

.form-control {
  display: block;
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  padding: 0 15px;
  line-height: 34px;
  overflow: hidden;
  border: #e7e6e6 solid 1px; }
  .form-control:focus, .form-control:hover, .form-control:active {
    outline: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.alert {
  margin-bottom: 0px; }

.card {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-color: #edeef1; }
  .card-header {
    padding: 10px;
    background-color: #ffffff; }
    .card-header .controls {
      margin-left: auto; }
    .card-header h3 {
      font-size: 16px;
      margin: 0;
      text-transform: capitalize; }
  .card-body {
    margin: 0;
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }
    .card-footer button {
      margin-right: 15px; }
      .card-footer button:last-child {
        margin-right: 0; }

.ant-modal-header {
  padding: 20px; }

.ant-select {
  flex: 1 1 auto; }
  .ant-select .ant-select-selection {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    display: block;
    padding: 0;
    height: 30px;
    overflow: hidden;
    border-color: #d3d3d3; }

.dropdown-menu {
  padding: 0;
  border: 0;
  width: auto !important;
  top: 100% !important;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(0, 20px) !important;
  transform: translate(0, 20px) !important; }
  .dropdown-menu.show {
    -webkit-transform: translate(0, 0px) !important;
    transform: translate(0, 0px) !important; }
  .dropdown-menu__item {
    font-size: 13px;
    padding: 6px 10px;
    width: auto !important; }
    .dropdown-menu__item.active {
      background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
      background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
      background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
      background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
      background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
      background-image: linear-gradient(to right, #ed7d31, #ffc000); }

.breadcrumb .breadcrumb-item {
  flex: 0 0 auto; }
  .breadcrumb .breadcrumb-item a {
    font-size: 13px; }
  .breadcrumb .breadcrumb-item.active {
    margin: 0; }
    .breadcrumb .breadcrumb-item.active span {
      font-size: 13px;
      font-weight: 600; }

.ant-table {
  margin: 0; }
  .ant-table-header, .ant-table-body {
    margin: 0; }
    .ant-table-header tr, .ant-table-body tr {
      margin: 0; }
      .ant-table-header tr td, .ant-table-body tr td {
        vertical-align: middle; }

.ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  flex: 0 0 auto;
  margin-right: 4px;
  max-width: 26px;
  min-width: 36px;
  max-height: 26px;
  min-height: 26px;
  border: solid 1px olivedrab;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-disabled a,
  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next a,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination .ant-pagination-prev a,
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-item a,
  .ant-pagination .ant-pagination-item .ant-pagination-item-link {
    flex: 0 0 auto;
    line-height: 8px !important;
    border: 0;
    height: auto;
    background-color: transparent; }
  .ant-pagination .ant-pagination-disabled.ant-pagination-item-active,
  .ant-pagination .ant-pagination-next.ant-pagination-item-active,
  .ant-pagination .ant-pagination-prev.ant-pagination-item-active,
  .ant-pagination .ant-pagination-item.ant-pagination-item-active {
    border-color: #ed7d31;
    background-color: #ed7d31;
    color: #ffffff;
    font-weight: 700;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  border: solid 1px #c3c3c3;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-item:hover,
  .ant-pagination .ant-pagination-next:hover,
  .ant-pagination .ant-pagination-prev:hover {
    border-color: #ed7d31;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .ant-pagination .ant-pagination-item:hover a,
    .ant-pagination .ant-pagination-next:hover a,
    .ant-pagination .ant-pagination-prev:hover a {
      color: #ed7d31;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-disabled {
  border: solid 1px #d7d7d7;
  background-color: #eeeeee; }

.ant-pagination .ant-pagination-total-text,
.ant-pagination .ant-pagination-options {
  width: auto !important; }
  .ant-pagination .ant-pagination-total-text .ant-select,
  .ant-pagination .ant-pagination-options .ant-select {
    margin: 0px !important; }

.ant-pagination .ant-pagination-total-text {
  margin-right: auto; }

.card {
  border: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: inset 0 0 0 0; }
  .card-header {
    padding: 10px;
    background-color: transparent;
    border-color: #e2e2e2; }
  .card-body {
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }

/*== ant-d override ==*/
.sentiment-facet {
  flex: 1 1 16%;
  margin-right: 20px;
  background-color: white; }

.sentiment-body {
  flex: 1 1 84%; }
  .sentiment-body .sentiment-search {
    margin-bottom: 5px; }
  .sentiment-body__content-table {
    margin: '0px 15px'; }
  .sentiment-body__table .result-count {
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 15px; }

.sentiment .ant-table-row {
  cursor: pointer; }

.sentiment .ant-table-thead > tr > th {
  font-weight: 600; }

.sentiment .sentiment__empty {
  margin-left: 15px; }

.active {
  font-weight: 600;
  background-color: #dbf3ff; }

.ant-pagination-total-text {
  padding-right: 10px; }

.dropdown-checkbox p {
  margin: 7px 0; }

.ant-popover-inner-content {
  padding: 5px 5px; }

.dropdown-select {
  width: 200px; }

.visibility-controller__popover {
  width: 70px;
  margin-left: 20; }
  .visibility-controller__popover i {
    margin: 2px 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px; }

.piechart-card {
  border: none;
  margin: 20px 0px; }

.ant-modal.sentiment-modal .ant-modal-body {
  padding: 50px 0 10px 0px; }
  .ant-modal.sentiment-modal .ant-modal-body .sentiment-body .sentiment__list {
    display: none; }
    .ant-modal.sentiment-modal .ant-modal-body .sentiment-body .sentiment__list li {
      margin: 2px;
      padding: 2px;
      border: 1px solid #c2c1c1;
      cursor: pointer; }
  .ant-modal.sentiment-modal .ant-modal-body .sentiment-body .sentiment__selected {
    margin: 15px; }
    .ant-modal.sentiment-modal .ant-modal-body .sentiment-body .sentiment__selected--item {
      padding: 2px;
      margin-top: 2px; }
      .ant-modal.sentiment-modal .ant-modal-body .sentiment-body .sentiment__selected--item .selected__title {
        font-weight: bold; }

/*---------Color-----------*/
/*---------Color-----------*/
/*---------Font-----------*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: 0;
  vertical-align: baseline; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav,
ul,
li,
ol {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .card, .facets-list {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  flex-wrap: nowrap; }

.flexbox, .btn, .card-header, .card-footer, .breadcrumb, .ant-pagination, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item, .facets-list__header {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.direction-col {
  -moz-flex-direction: column;
  flex-direction: column; }

.all-center, .btn, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  -moz-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -moz-justify-content: center;
  justify-content: center; }

.justify-between, .facets-list__header {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .card-header, .breadcrumb, .ant-pagination, .facets-list__header {
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -moz-align-items: stretch;
  align-items: stretch; }

.item-wrap {
  flex-wrap: wrap; }

.btn {
  cursor: pointer;
  overflow: hidden;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .btn:focus, .btn:active, .btn:hover, .btn:visited, .btn:focus-within {
    box-shadow: 0 0 0 0 !important;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
  .btn.btn-primary {
    color: #ffffff;
    border: 0px;
    background-size: 200%;
    background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
    background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
    background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
    background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
    background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
    background-image: linear-gradient(to right, #ed7d31, #ffc000);
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:hover, .btn.btn-primary:visited, .btn.btn-primary:focus-within {
      background-position: 100%;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }
  .btn i {
    flex: 0 1 auto;
    font-size: 16px;
    margin-right: 8px; }
  .btn span {
    flex: 0 1 auto; }
  .btn.icon {
    padding: 0;
    border: 0 !important;
    background: transparent !important; }
    .btn.icon i {
      color: #737373; }
    .btn.icon:hover {
      background: transparent; }

.form-control {
  display: block;
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  padding: 0 15px;
  line-height: 34px;
  overflow: hidden;
  border: #e7e6e6 solid 1px; }
  .form-control:focus, .form-control:hover, .form-control:active {
    outline: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.alert {
  margin-bottom: 0px; }

.card {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-color: #edeef1; }
  .card-header {
    padding: 10px;
    background-color: #ffffff; }
    .card-header .controls {
      margin-left: auto; }
    .card-header h3 {
      font-size: 16px;
      margin: 0;
      text-transform: capitalize; }
  .card-body {
    margin: 0;
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }
    .card-footer button {
      margin-right: 15px; }
      .card-footer button:last-child {
        margin-right: 0; }

.ant-modal-header {
  padding: 20px; }

.ant-select {
  flex: 1 1 auto; }
  .ant-select .ant-select-selection {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    display: block;
    padding: 0;
    height: 30px;
    overflow: hidden;
    border-color: #d3d3d3; }

.dropdown-menu {
  padding: 0;
  border: 0;
  width: auto !important;
  top: 100% !important;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(0, 20px) !important;
  transform: translate(0, 20px) !important; }
  .dropdown-menu.show {
    -webkit-transform: translate(0, 0px) !important;
    transform: translate(0, 0px) !important; }
  .dropdown-menu__item {
    font-size: 13px;
    padding: 6px 10px;
    width: auto !important; }
    .dropdown-menu__item.active {
      background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
      background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
      background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
      background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
      background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
      background-image: linear-gradient(to right, #ed7d31, #ffc000); }

.breadcrumb .breadcrumb-item {
  flex: 0 0 auto; }
  .breadcrumb .breadcrumb-item a {
    font-size: 13px; }
  .breadcrumb .breadcrumb-item.active {
    margin: 0; }
    .breadcrumb .breadcrumb-item.active span {
      font-size: 13px;
      font-weight: 600; }

.ant-table {
  margin: 0; }
  .ant-table-header, .ant-table-body {
    margin: 0; }
    .ant-table-header tr, .ant-table-body tr {
      margin: 0; }
      .ant-table-header tr td, .ant-table-body tr td {
        vertical-align: middle; }

.ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  flex: 0 0 auto;
  margin-right: 4px;
  max-width: 26px;
  min-width: 36px;
  max-height: 26px;
  min-height: 26px;
  border: solid 1px olivedrab;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-disabled a,
  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next a,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination .ant-pagination-prev a,
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-item a,
  .ant-pagination .ant-pagination-item .ant-pagination-item-link {
    flex: 0 0 auto;
    line-height: 8px !important;
    border: 0;
    height: auto;
    background-color: transparent; }
  .ant-pagination .ant-pagination-disabled.ant-pagination-item-active,
  .ant-pagination .ant-pagination-next.ant-pagination-item-active,
  .ant-pagination .ant-pagination-prev.ant-pagination-item-active,
  .ant-pagination .ant-pagination-item.ant-pagination-item-active {
    border-color: #ed7d31;
    background-color: #ed7d31;
    color: #ffffff;
    font-weight: 700;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  border: solid 1px #c3c3c3;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-item:hover,
  .ant-pagination .ant-pagination-next:hover,
  .ant-pagination .ant-pagination-prev:hover {
    border-color: #ed7d31;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .ant-pagination .ant-pagination-item:hover a,
    .ant-pagination .ant-pagination-next:hover a,
    .ant-pagination .ant-pagination-prev:hover a {
      color: #ed7d31;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-disabled {
  border: solid 1px #d7d7d7;
  background-color: #eeeeee; }

.ant-pagination .ant-pagination-total-text,
.ant-pagination .ant-pagination-options {
  width: auto !important; }
  .ant-pagination .ant-pagination-total-text .ant-select,
  .ant-pagination .ant-pagination-options .ant-select {
    margin: 0px !important; }

.ant-pagination .ant-pagination-total-text {
  margin-right: auto; }

.card {
  border: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: inset 0 0 0 0; }
  .card-header {
    padding: 10px;
    background-color: transparent;
    border-color: #e2e2e2; }
  .card-body {
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }

/*== ant-d override ==*/
.facets-list__header {
  flex: 1 1 auto;
  padding: 0 15px;
  border-bottom: solid 1px #f2f2f2; }
  .facets-list__header h3 {
    flex: 1 1 auto;
    margin: 0;
    line-height: 40px;
    font-size: 16px; }
  .facets-list__header .close-btn {
    flex: 0 1 auto;
    font-size: 14px;
    cursor: pointer; }

.facets-list__item {
  flex: 1 1 auto; }
  .facets-list__item li {
    display: block;
    border-bottom: #f2f2f2 solid 1px; }
    .facets-list__item li .btn {
      border: none;
      background-color: #ffffff;
      padding: 12px 15px;
      display: block;
      text-align: left;
      line-height: 1;
      height: auto;
      width: 100%;
      font-size: 13px !important;
      font-weight: 550;
      text-transform: capitalize;
      font-size: 14px;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      position: relative;
      color: #2c2c2c; }
      .facets-list__item li .btn.selected:before {
        border-left-color: #1890ff; }
      .facets-list__item li .btn:after, .facets-list__item li .btn:before {
        top: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; }
      .facets-list__item li .btn:after {
        border-left-color: #ffffff;
        border-width: 5px;
        margin-top: -5px;
        right: 15px; }
      .facets-list__item li .btn:before {
        border-left-color: #c4c4c4;
        border-width: 7px;
        margin-top: -7px;
        right: 11px; }
    .facets-list__item li .facets-submenu {
      margin: 0;
      padding: 0px;
      background-color: #fafafa; }
      .facets-list__item li .facets-submenu .sub-button {
        padding: 10px 20px;
        font-size: 13px;
        line-height: 1;
        border: 0;
        cursor: pointer;
        margin: 1px 0;
        display: grid;
        grid-template-columns: 20fr 1fr; }
        .facets-list__item li .facets-submenu .sub-button .facet-subitem {
          display: grid;
          grid-template-columns: 80% 20%; }
          .facets-list__item li .facets-submenu .sub-button .facet-subitem :last-child {
            text-align: right;
            margin-right: 3px; }

/*---------Color-----------*/
/*---------Color-----------*/
/*---------Font-----------*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: 0;
  vertical-align: baseline; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav,
ul,
li,
ol {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .card {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  flex-wrap: nowrap; }

.flexbox, .btn, .card-header, .card-footer, .breadcrumb, .ant-pagination, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item, .add-block {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.direction-col {
  -moz-flex-direction: column;
  flex-direction: column; }

.all-center, .btn, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  -moz-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .card-header, .breadcrumb, .ant-pagination {
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -moz-align-items: stretch;
  align-items: stretch; }

.item-wrap {
  flex-wrap: wrap; }

.btn {
  cursor: pointer;
  overflow: hidden;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .btn:focus, .btn:active, .btn:hover, .btn:visited, .btn:focus-within {
    box-shadow: 0 0 0 0 !important;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
  .btn.btn-primary {
    color: #ffffff;
    border: 0px;
    background-size: 200%;
    background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
    background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
    background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
    background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
    background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
    background-image: linear-gradient(to right, #ed7d31, #ffc000);
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:hover, .btn.btn-primary:visited, .btn.btn-primary:focus-within {
      background-position: 100%;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }
  .btn i {
    flex: 0 1 auto;
    font-size: 16px;
    margin-right: 8px; }
  .btn span {
    flex: 0 1 auto; }
  .btn.icon {
    padding: 0;
    border: 0 !important;
    background: transparent !important; }
    .btn.icon i {
      color: #737373; }
    .btn.icon:hover {
      background: transparent; }

.form-control {
  display: block;
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  padding: 0 15px;
  line-height: 34px;
  overflow: hidden;
  border: #e7e6e6 solid 1px; }
  .form-control:focus, .form-control:hover, .form-control:active {
    outline: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.alert {
  margin-bottom: 0px; }

.card {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-color: #edeef1; }
  .card-header {
    padding: 10px;
    background-color: #ffffff; }
    .card-header .controls {
      margin-left: auto; }
    .card-header h3 {
      font-size: 16px;
      margin: 0;
      text-transform: capitalize; }
  .card-body {
    margin: 0;
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }
    .card-footer button {
      margin-right: 15px; }
      .card-footer button:last-child {
        margin-right: 0; }

.ant-modal-header {
  padding: 20px; }

.ant-select {
  flex: 1 1 auto; }
  .ant-select .ant-select-selection {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    display: block;
    padding: 0;
    height: 30px;
    overflow: hidden;
    border-color: #d3d3d3; }

.dropdown-menu {
  padding: 0;
  border: 0;
  width: auto !important;
  top: 100% !important;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(0, 20px) !important;
  transform: translate(0, 20px) !important; }
  .dropdown-menu.show {
    -webkit-transform: translate(0, 0px) !important;
    transform: translate(0, 0px) !important; }
  .dropdown-menu__item {
    font-size: 13px;
    padding: 6px 10px;
    width: auto !important; }
    .dropdown-menu__item.active {
      background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
      background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
      background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
      background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
      background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
      background-image: linear-gradient(to right, #ed7d31, #ffc000); }

.breadcrumb .breadcrumb-item {
  flex: 0 0 auto; }
  .breadcrumb .breadcrumb-item a {
    font-size: 13px; }
  .breadcrumb .breadcrumb-item.active {
    margin: 0; }
    .breadcrumb .breadcrumb-item.active span {
      font-size: 13px;
      font-weight: 600; }

.ant-table {
  margin: 0; }
  .ant-table-header, .ant-table-body {
    margin: 0; }
    .ant-table-header tr, .ant-table-body tr {
      margin: 0; }
      .ant-table-header tr td, .ant-table-body tr td {
        vertical-align: middle; }

.ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  flex: 0 0 auto;
  margin-right: 4px;
  max-width: 26px;
  min-width: 36px;
  max-height: 26px;
  min-height: 26px;
  border: solid 1px olivedrab;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-disabled a,
  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next a,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination .ant-pagination-prev a,
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-item a,
  .ant-pagination .ant-pagination-item .ant-pagination-item-link {
    flex: 0 0 auto;
    line-height: 8px !important;
    border: 0;
    height: auto;
    background-color: transparent; }
  .ant-pagination .ant-pagination-disabled.ant-pagination-item-active,
  .ant-pagination .ant-pagination-next.ant-pagination-item-active,
  .ant-pagination .ant-pagination-prev.ant-pagination-item-active,
  .ant-pagination .ant-pagination-item.ant-pagination-item-active {
    border-color: #ed7d31;
    background-color: #ed7d31;
    color: #ffffff;
    font-weight: 700;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  border: solid 1px #c3c3c3;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-item:hover,
  .ant-pagination .ant-pagination-next:hover,
  .ant-pagination .ant-pagination-prev:hover {
    border-color: #ed7d31;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .ant-pagination .ant-pagination-item:hover a,
    .ant-pagination .ant-pagination-next:hover a,
    .ant-pagination .ant-pagination-prev:hover a {
      color: #ed7d31;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-disabled {
  border: solid 1px #d7d7d7;
  background-color: #eeeeee; }

.ant-pagination .ant-pagination-total-text,
.ant-pagination .ant-pagination-options {
  width: auto !important; }
  .ant-pagination .ant-pagination-total-text .ant-select,
  .ant-pagination .ant-pagination-options .ant-select {
    margin: 0px !important; }

.ant-pagination .ant-pagination-total-text {
  margin-right: auto; }

.card {
  border: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: inset 0 0 0 0; }
  .card-header {
    padding: 10px;
    background-color: transparent;
    border-color: #e2e2e2; }
  .card-body {
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }

/*== ant-d override ==*/
.add-block {
  margin: 2%; }
  .add-block .list-batchids {
    margin-right: 20px;
    width: 50%; }
    .add-block .list-batchids .list-header {
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      font-weight: bold; }
      .add-block .list-batchids .list-header .search {
        width: 200px; }
    .add-block .list-batchids .list-item {
      padding: 10px;
      display: flex;
      justify-content: space-between; }
      .add-block .list-batchids .list-item i {
        color: red; }
  .add-block .input-form {
    width: 35%; }
    .add-block .input-form__header {
      width: 100%;
      display: flex; }
      .add-block .input-form__header i {
        position: absolute;
        right: 20px; }
    .add-block .input-form__body .input__file {
      width: 100%;
      padding: 5px 0; }
      .add-block .input-form__body .input__file .input__hidden {
        padding: 0;
        margin: 0; }
      .add-block .input-form__body .input__file button {
        margin: 0;
        width: 100%; }
    .add-block .input-form__body .input-wrapper {
      width: 100%; }
      .add-block .input-form__body .input-wrapper input {
        height: 40px; }

.input__file {
  padding: 0px 15px;
  border-radius: 3px; }
  .input__file .input__hidden {
    line-height: 36px;
    cursor: pointer;
    position: absolute;
    z-index: 999;
    opacity: 0;
    min-width: 220px;
    margin: 0px 10px 10px -15px; }
  .input__file .input__shown {
    color: #242424;
    min-width: 220px;
    cursor: pointer;
    margin: 0px 10px 10px -15px;
    text-align: left;
    height: 40px; }
    .input__file .input__shown i {
      font-weight: bold; }
    .input__file .input__shown .input__file__details {
      color: #44680f;
      position: absolute;
      right: 10px; }

/*---------Color-----------*/
/*---------Color-----------*/
/*---------Font-----------*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: 0;
  vertical-align: baseline; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav,
ul,
li,
ol {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .card {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  flex-wrap: nowrap; }

.flexbox, .btn, .card-header, .card-footer, .breadcrumb, .ant-pagination, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item, .logo {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.direction-col {
  -moz-flex-direction: column;
  flex-direction: column; }

.all-center, .btn, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item, .logo {
  -moz-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .card-header, .breadcrumb, .ant-pagination {
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -moz-align-items: stretch;
  align-items: stretch; }

.item-wrap {
  flex-wrap: wrap; }

.btn {
  cursor: pointer;
  overflow: hidden;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .btn:focus, .btn:active, .btn:hover, .btn:visited, .btn:focus-within {
    box-shadow: 0 0 0 0 !important;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
  .btn.btn-primary {
    color: #ffffff;
    border: 0px;
    background-size: 200%;
    background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
    background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
    background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
    background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
    background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
    background-image: linear-gradient(to right, #ed7d31, #ffc000);
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:hover, .btn.btn-primary:visited, .btn.btn-primary:focus-within {
      background-position: 100%;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }
  .btn i {
    flex: 0 1 auto;
    font-size: 16px;
    margin-right: 8px; }
  .btn span {
    flex: 0 1 auto; }
  .btn.icon {
    padding: 0;
    border: 0 !important;
    background: transparent !important; }
    .btn.icon i {
      color: #737373; }
    .btn.icon:hover {
      background: transparent; }

.form-control {
  display: block;
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  padding: 0 15px;
  line-height: 34px;
  overflow: hidden;
  border: #e7e6e6 solid 1px; }
  .form-control:focus, .form-control:hover, .form-control:active {
    outline: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.alert {
  margin-bottom: 0px; }

.card {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-color: #edeef1; }
  .card-header {
    padding: 10px;
    background-color: #ffffff; }
    .card-header .controls {
      margin-left: auto; }
    .card-header h3 {
      font-size: 16px;
      margin: 0;
      text-transform: capitalize; }
  .card-body {
    margin: 0;
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }
    .card-footer button {
      margin-right: 15px; }
      .card-footer button:last-child {
        margin-right: 0; }

.ant-modal-header {
  padding: 20px; }

.ant-select {
  flex: 1 1 auto; }
  .ant-select .ant-select-selection {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    display: block;
    padding: 0;
    height: 30px;
    overflow: hidden;
    border-color: #d3d3d3; }

.dropdown-menu {
  padding: 0;
  border: 0;
  width: auto !important;
  top: 100% !important;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(0, 20px) !important;
  transform: translate(0, 20px) !important; }
  .dropdown-menu.show {
    -webkit-transform: translate(0, 0px) !important;
    transform: translate(0, 0px) !important; }
  .dropdown-menu__item {
    font-size: 13px;
    padding: 6px 10px;
    width: auto !important; }
    .dropdown-menu__item.active {
      background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
      background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
      background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
      background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
      background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
      background-image: linear-gradient(to right, #ed7d31, #ffc000); }

.breadcrumb .breadcrumb-item {
  flex: 0 0 auto; }
  .breadcrumb .breadcrumb-item a {
    font-size: 13px; }
  .breadcrumb .breadcrumb-item.active {
    margin: 0; }
    .breadcrumb .breadcrumb-item.active span {
      font-size: 13px;
      font-weight: 600; }

.ant-table {
  margin: 0; }
  .ant-table-header, .ant-table-body {
    margin: 0; }
    .ant-table-header tr, .ant-table-body tr {
      margin: 0; }
      .ant-table-header tr td, .ant-table-body tr td {
        vertical-align: middle; }

.ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  flex: 0 0 auto;
  margin-right: 4px;
  max-width: 26px;
  min-width: 36px;
  max-height: 26px;
  min-height: 26px;
  border: solid 1px olivedrab;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-disabled a,
  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next a,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination .ant-pagination-prev a,
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-item a,
  .ant-pagination .ant-pagination-item .ant-pagination-item-link {
    flex: 0 0 auto;
    line-height: 8px !important;
    border: 0;
    height: auto;
    background-color: transparent; }
  .ant-pagination .ant-pagination-disabled.ant-pagination-item-active,
  .ant-pagination .ant-pagination-next.ant-pagination-item-active,
  .ant-pagination .ant-pagination-prev.ant-pagination-item-active,
  .ant-pagination .ant-pagination-item.ant-pagination-item-active {
    border-color: #ed7d31;
    background-color: #ed7d31;
    color: #ffffff;
    font-weight: 700;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  border: solid 1px #c3c3c3;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-item:hover,
  .ant-pagination .ant-pagination-next:hover,
  .ant-pagination .ant-pagination-prev:hover {
    border-color: #ed7d31;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .ant-pagination .ant-pagination-item:hover a,
    .ant-pagination .ant-pagination-next:hover a,
    .ant-pagination .ant-pagination-prev:hover a {
      color: #ed7d31;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-disabled {
  border: solid 1px #d7d7d7;
  background-color: #eeeeee; }

.ant-pagination .ant-pagination-total-text,
.ant-pagination .ant-pagination-options {
  width: auto !important; }
  .ant-pagination .ant-pagination-total-text .ant-select,
  .ant-pagination .ant-pagination-options .ant-select {
    margin: 0px !important; }

.ant-pagination .ant-pagination-total-text {
  margin-right: auto; }

.card {
  border: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: inset 0 0 0 0; }
  .card-header {
    padding: 10px;
    background-color: transparent;
    border-color: #e2e2e2; }
  .card-body {
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }

/*== ant-d override ==*/
.logo {
  margin-bottom: 10px;
  cursor: pointer; }
  .logo span {
    flex: 0 0 auto;
    width: 160px; }

/*---------Color-----------*/
/*---------Color-----------*/
/*---------Font-----------*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: 0;
  vertical-align: baseline; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav,
ul,
li,
ol {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .card {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  flex-wrap: nowrap; }

.flexbox, .btn, .card-header, .card-footer, .breadcrumb, .ant-pagination, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item, .login-warpper, .login-warpper__content--list li, .login-warpper__content--list li.forgot-password {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.direction-col {
  -moz-flex-direction: column;
  flex-direction: column; }

.all-center, .btn, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item, .login-warpper, .login-warpper__content--list li {
  -moz-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -moz-justify-content: center;
  justify-content: center; }

.justify-between, .login-warpper__content--list li.forgot-password {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .card-header, .breadcrumb, .ant-pagination, .login-warpper__content--list li.forgot-password {
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -moz-align-items: stretch;
  align-items: stretch; }

.item-wrap {
  flex-wrap: wrap; }

.btn {
  cursor: pointer;
  overflow: hidden;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .btn:focus, .btn:active, .btn:hover, .btn:visited, .btn:focus-within {
    box-shadow: 0 0 0 0 !important;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
  .btn.btn-primary {
    color: #ffffff;
    border: 0px;
    background-size: 200%;
    background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
    background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
    background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
    background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
    background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
    background-image: linear-gradient(to right, #ed7d31, #ffc000);
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:hover, .btn.btn-primary:visited, .btn.btn-primary:focus-within {
      background-position: 100%;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }
  .btn i {
    flex: 0 1 auto;
    font-size: 16px;
    margin-right: 8px; }
  .btn span {
    flex: 0 1 auto; }
  .btn.icon {
    padding: 0;
    border: 0 !important;
    background: transparent !important; }
    .btn.icon i {
      color: #737373; }
    .btn.icon:hover {
      background: transparent; }

.form-control {
  display: block;
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  padding: 0 15px;
  line-height: 34px;
  overflow: hidden;
  border: #e7e6e6 solid 1px; }
  .form-control:focus, .form-control:hover, .form-control:active {
    outline: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.alert {
  margin-bottom: 0px; }

.card {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-color: #edeef1; }
  .card-header {
    padding: 10px;
    background-color: #ffffff; }
    .card-header .controls {
      margin-left: auto; }
    .card-header h3 {
      font-size: 16px;
      margin: 0;
      text-transform: capitalize; }
  .card-body {
    margin: 0;
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }
    .card-footer button {
      margin-right: 15px; }
      .card-footer button:last-child {
        margin-right: 0; }

.ant-modal-header {
  padding: 20px; }

.ant-select {
  flex: 1 1 auto; }
  .ant-select .ant-select-selection {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    display: block;
    padding: 0;
    height: 30px;
    overflow: hidden;
    border-color: #d3d3d3; }

.dropdown-menu {
  padding: 0;
  border: 0;
  width: auto !important;
  top: 100% !important;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(0, 20px) !important;
  transform: translate(0, 20px) !important; }
  .dropdown-menu.show {
    -webkit-transform: translate(0, 0px) !important;
    transform: translate(0, 0px) !important; }
  .dropdown-menu__item {
    font-size: 13px;
    padding: 6px 10px;
    width: auto !important; }
    .dropdown-menu__item.active {
      background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
      background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
      background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
      background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
      background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
      background-image: linear-gradient(to right, #ed7d31, #ffc000); }

.breadcrumb .breadcrumb-item {
  flex: 0 0 auto; }
  .breadcrumb .breadcrumb-item a {
    font-size: 13px; }
  .breadcrumb .breadcrumb-item.active {
    margin: 0; }
    .breadcrumb .breadcrumb-item.active span {
      font-size: 13px;
      font-weight: 600; }

.ant-table {
  margin: 0; }
  .ant-table-header, .ant-table-body {
    margin: 0; }
    .ant-table-header tr, .ant-table-body tr {
      margin: 0; }
      .ant-table-header tr td, .ant-table-body tr td {
        vertical-align: middle; }

.ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  flex: 0 0 auto;
  margin-right: 4px;
  max-width: 26px;
  min-width: 36px;
  max-height: 26px;
  min-height: 26px;
  border: solid 1px olivedrab;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-disabled a,
  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next a,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination .ant-pagination-prev a,
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-item a,
  .ant-pagination .ant-pagination-item .ant-pagination-item-link {
    flex: 0 0 auto;
    line-height: 8px !important;
    border: 0;
    height: auto;
    background-color: transparent; }
  .ant-pagination .ant-pagination-disabled.ant-pagination-item-active,
  .ant-pagination .ant-pagination-next.ant-pagination-item-active,
  .ant-pagination .ant-pagination-prev.ant-pagination-item-active,
  .ant-pagination .ant-pagination-item.ant-pagination-item-active {
    border-color: #ed7d31;
    background-color: #ed7d31;
    color: #ffffff;
    font-weight: 700;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  border: solid 1px #c3c3c3;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-item:hover,
  .ant-pagination .ant-pagination-next:hover,
  .ant-pagination .ant-pagination-prev:hover {
    border-color: #ed7d31;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .ant-pagination .ant-pagination-item:hover a,
    .ant-pagination .ant-pagination-next:hover a,
    .ant-pagination .ant-pagination-prev:hover a {
      color: #ed7d31;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-disabled {
  border: solid 1px #d7d7d7;
  background-color: #eeeeee; }

.ant-pagination .ant-pagination-total-text,
.ant-pagination .ant-pagination-options {
  width: auto !important; }
  .ant-pagination .ant-pagination-total-text .ant-select,
  .ant-pagination .ant-pagination-options .ant-select {
    margin: 0px !important; }

.ant-pagination .ant-pagination-total-text {
  margin-right: auto; }

.card {
  border: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: inset 0 0 0 0; }
  .card-header {
    padding: 10px;
    background-color: transparent;
    border-color: #e2e2e2; }
  .card-body {
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }

/*== ant-d override ==*/
.login-warpper {
  height: 100vh; }
  .login-warpper .has-error {
    border-color: red; }
  .login-warpper__content {
    flex: 0 1 auto;
    padding: 30px;
    min-width: 500px;
    background-color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.06);
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .login-warpper__content h3 {
      font-size: 18px;
      color: #397ef3;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 30px;
      text-align: center; }
    .login-warpper__content--list {
      flex: 1 1 auto; }
      .login-warpper__content--list li {
        flex: 0 1 auto;
        margin-bottom: 20px; }
        .login-warpper__content--list li:last-child {
          margin-bottom: 0px;
          margin-top: 50px;
          font-size: 12px;
          color: #898989; }
        .login-warpper__content--list li p {
          font-size: 12px; }
          .login-warpper__content--list li p.error-message {
            color: red;
            font-size: 12px; }
        .login-warpper__content--list li span {
          flex: 0 1 auto; }
          .login-warpper__content--list li span a {
            color: #b6b6b6; }
            .login-warpper__content--list li span a:hover {
              color: #397ef3; }
        .login-warpper__content--list li .btn {
          padding: 12px 15px;
          min-width: 100%;
          margin: 20px 0 10px 0; }
        .login-warpper__content--list li.login-block {
          margin: 0px; }
          .login-warpper__content--list li.login-block .input-wrapper {
            padding: 2px;
            border-color: #b9b9b9;
            border-width: 1px;
            border-style: solid;
            width: 100%;
            position: relative; }
            .login-warpper__content--list li.login-block .input-wrapper .form-control {
              border: 0;
              padding: 8px 10px;
              height: auto; }
            .login-warpper__content--list li.login-block .input-wrapper .error-message {
              position: absolute;
              right: 10px;
              top: 50%;
              color: red;
              font-size: 12px;
              font-style: italic;
              -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
          .login-warpper__content--list li.login-block:nth-child(2) {
            margin: 0; }
            .login-warpper__content--list li.login-block:nth-child(2) .input-wrapper {
              border-top: 0px; }
        .login-warpper__content--list li.forgot-password span {
          font-size: 13px;
          flex: 0 0 auto;
          text-transform: capitalize; }

/*---------Color-----------*/
/*---------Color-----------*/
/*---------Font-----------*/
/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: 0;
  vertical-align: baseline; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav,
ul,
li,
ol {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

/*=====
alpha
========*/
/*=====
linear-gradien
========*/
/*====
transition
======*/
/*====
transform
======*/
/*----
placeholder
-----*/
/*----
flex
-----*/
/*-=====
opasity
=======*/
/*-=====
appearance
=======*/
/*-=====
filter
=======*/
/*-=====
react select
=======*/
/*-=====
mediaquery
=======*/
/*-=====
Animation
=======*/
.flex-column, .card {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  flex-wrap: nowrap; }

.flexbox, .btn, .card-header, .card-footer, .breadcrumb, .ant-pagination, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item, .main-wrapper .main-header, .main-wrapper .main-header__menu, .main-wrapper .main-header__menu--list, .main-wrapper .main-header__menu--list li .dropdown .btn, .main-wrapper .main-header__menu--list li .dropdown .dropdown-menu .dropdown-item {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: nowrap;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.direction-col {
  -moz-flex-direction: column;
  flex-direction: column; }

.all-center, .btn, .ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item, .main-wrapper .main-header__menu--list li .dropdown .btn {
  -moz-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  align-items: center; }

.justify-center {
  -moz-justify-content: center;
  justify-content: center; }

.justify-between {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.justify-around {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.justify-start {
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.justify-end {
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.item-center, .card-header, .breadcrumb, .ant-pagination, .main-wrapper .main-header, .main-wrapper .main-header__menu, .main-wrapper .main-header__menu--list, .main-wrapper .main-header__menu--list li .dropdown .dropdown-menu .dropdown-item {
  -moz-align-items: center;
  align-items: center; }

.item-start {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.item-end {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.item-stretch {
  -moz-align-items: stretch;
  align-items: stretch; }

.item-wrap {
  flex-wrap: wrap; }

.btn {
  cursor: pointer;
  overflow: hidden;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .btn:focus, .btn:active, .btn:hover, .btn:visited, .btn:focus-within {
    box-shadow: 0 0 0 0 !important;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
  .btn.btn-primary {
    color: #ffffff;
    border: 0px;
    background-size: 200%;
    background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
    background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
    background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
    background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
    background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
    background-image: linear-gradient(to right, #ed7d31, #ffc000);
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:hover, .btn.btn-primary:visited, .btn.btn-primary:focus-within {
      background-position: 100%;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }
  .btn i {
    flex: 0 1 auto;
    font-size: 16px;
    margin-right: 8px; }
  .btn span {
    flex: 0 1 auto; }
  .btn.icon {
    padding: 0;
    border: 0 !important;
    background: transparent !important; }
    .btn.icon i {
      color: #737373; }
    .btn.icon:hover {
      background: transparent; }

.form-control {
  display: block;
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  padding: 0 15px;
  line-height: 34px;
  overflow: hidden;
  border: #e7e6e6 solid 1px; }
  .form-control:focus, .form-control:hover, .form-control:active {
    outline: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.alert {
  margin-bottom: 0px; }

.card {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-color: #edeef1; }
  .card-header {
    padding: 10px;
    background-color: #ffffff; }
    .card-header .controls {
      margin-left: auto; }
    .card-header h3 {
      font-size: 16px;
      margin: 0;
      text-transform: capitalize; }
  .card-body {
    margin: 0;
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }
    .card-footer button {
      margin-right: 15px; }
      .card-footer button:last-child {
        margin-right: 0; }

.ant-modal-header {
  padding: 20px; }

.ant-select {
  flex: 1 1 auto; }
  .ant-select .ant-select-selection {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    box-shadow: 0 0 0 transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    display: block;
    padding: 0;
    height: 30px;
    overflow: hidden;
    border-color: #d3d3d3; }

.dropdown-menu {
  padding: 0;
  border: 0;
  width: auto !important;
  top: 100% !important;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(0, 20px) !important;
  transform: translate(0, 20px) !important; }
  .dropdown-menu.show {
    -webkit-transform: translate(0, 0px) !important;
    transform: translate(0, 0px) !important; }
  .dropdown-menu__item {
    font-size: 13px;
    padding: 6px 10px;
    width: auto !important; }
    .dropdown-menu__item.active {
      background-image: -webkit-linear-gradient(legacy-direction(to right), #ed7d31, #ffc000);
      background-image: -webkit-linear-gradient(to right, #ed7d31, #ffc000);
      background: -ms-linear-gradient(to right, #ed7d31, #ffc000);
      background-image: -webkit-gradient(linear, left top, right top, from(#ed7d31), to(#ffc000));
      background-image: -webkit-linear-gradient(left, #ed7d31, #ffc000);
      background-image: linear-gradient(to right, #ed7d31, #ffc000); }

.breadcrumb .breadcrumb-item {
  flex: 0 0 auto; }
  .breadcrumb .breadcrumb-item a {
    font-size: 13px; }
  .breadcrumb .breadcrumb-item.active {
    margin: 0; }
    .breadcrumb .breadcrumb-item.active span {
      font-size: 13px;
      font-weight: 600; }

.ant-table {
  margin: 0; }
  .ant-table-header, .ant-table-body {
    margin: 0; }
    .ant-table-header tr, .ant-table-body tr {
      margin: 0; }
      .ant-table-header tr td, .ant-table-body tr td {
        vertical-align: middle; }

.ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item {
  flex: 0 0 auto;
  margin-right: 4px;
  max-width: 26px;
  min-width: 36px;
  max-height: 26px;
  min-height: 26px;
  border: solid 1px olivedrab;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-disabled a,
  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next a,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination .ant-pagination-prev a,
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-item a,
  .ant-pagination .ant-pagination-item .ant-pagination-item-link {
    flex: 0 0 auto;
    line-height: 8px !important;
    border: 0;
    height: auto;
    background-color: transparent; }
  .ant-pagination .ant-pagination-disabled.ant-pagination-item-active,
  .ant-pagination .ant-pagination-next.ant-pagination-item-active,
  .ant-pagination .ant-pagination-prev.ant-pagination-item-active,
  .ant-pagination .ant-pagination-item.ant-pagination-item-active {
    border-color: #ed7d31;
    background-color: #ed7d31;
    color: #ffffff;
    font-weight: 700;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  border: solid 1px #c3c3c3;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  .ant-pagination .ant-pagination-item:hover,
  .ant-pagination .ant-pagination-next:hover,
  .ant-pagination .ant-pagination-prev:hover {
    border-color: #ed7d31;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .ant-pagination .ant-pagination-item:hover a,
    .ant-pagination .ant-pagination-next:hover a,
    .ant-pagination .ant-pagination-prev:hover a {
      color: #ed7d31;
      -webkit-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out; }

.ant-pagination .ant-pagination-disabled {
  border: solid 1px #d7d7d7;
  background-color: #eeeeee; }

.ant-pagination .ant-pagination-total-text,
.ant-pagination .ant-pagination-options {
  width: auto !important; }
  .ant-pagination .ant-pagination-total-text .ant-select,
  .ant-pagination .ant-pagination-options .ant-select {
    margin: 0px !important; }

.ant-pagination .ant-pagination-total-text {
  margin-right: auto; }

.card {
  border: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: inset 0 0 0 0; }
  .card-header {
    padding: 10px;
    background-color: transparent;
    border-color: #e2e2e2; }
  .card-body {
    padding: 10px; }
    .card-body h2 {
      margin-bottom: 10px; }
  .card-footer {
    padding: 10px; }

/*== ant-d override ==*/
.main-wrapper {
  -webkit-animation: fadeIn 0.8s;
  animation: fadeIn 0.8s; }
  .main-wrapper .ant-layout-sider {
    background-color: white; }
    .main-wrapper .ant-layout-sider .ant-menu-item-selected {
      background-color: #f7cca6;
      border-right: 1px solid #de893f;
      color: black; }
    .main-wrapper .ant-layout-sider:after {
      border: none; }
  .main-wrapper .ant-layout-sider-trigger {
    border-top: 1px solid #f7cca6;
    background-color: white;
    color: black; }
  .main-wrapper .ant-menu-inline .ant-menu-item::after {
    border: none; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .main-wrapper .main-header {
    margin-bottom: 20px;
    background-color: white;
    max-height: 9vh;
    min-height: 9vh;
    min-height: 60px;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out; }
    .main-wrapper .main-header__brand {
      flex: 0 1 auto;
      font-size: 16px;
      cursor: pointer;
      color: white;
      width: 50px;
      height: 20px;
      margin-top: -25px; }
      .main-wrapper .main-header__brand a {
        display: block; }
        .main-wrapper .main-header__brand a span {
          display: block;
          min-width: 160px; }
    .main-wrapper .main-header__title {
      width: 100%;
      text-align: center; }
      .main-wrapper .main-header__title h2 {
        color: #de893f;
        font-size: 30px;
        line-height: 1;
        display: none; }
    .main-wrapper .main-header__menu {
      flex: 0 0 auto;
      margin-left: auto;
      margin-right: 15px; }
      .main-wrapper .main-header__menu--list {
        margin: 0; }
        .main-wrapper .main-header__menu--list li {
          flex: 0 1 auto; }
          .main-wrapper .main-header__menu--list li:last-child {
            margin-left: 20px; }
          .main-wrapper .main-header__menu--list li a {
            display: block;
            padding: 15px 10px;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
            position: relative;
            color: #7d8b97;
            font-weight: 600;
            text-decoration: none; }
            .main-wrapper .main-header__menu--list li a:hover, .main-wrapper .main-header__menu--list li a.active {
              margin: 0;
              color: #ed7d31; }
          .main-wrapper .main-header__menu--list li .dropdown {
            min-width: auto !important; }
            .main-wrapper .main-header__menu--list li .dropdown .btn {
              width: 35px;
              height: 35px;
              overflow: hidden;
              margin: 0;
              padding: 0;
              border: 1px solid transparent;
              background-color: #ffffff;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%; }
              .main-wrapper .main-header__menu--list li .dropdown .btn img {
                width: 100%; }
              .main-wrapper .main-header__menu--list li .dropdown .btn:after {
                border: 0px;
                width: 0px;
                height: 0px;
                margin: 0px; }
            .main-wrapper .main-header__menu--list li .dropdown .dropdown-menu {
              left: auto !important;
              right: 0px !important;
              margin-top: 13px;
              padding: 0;
              border-radius: 3px;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -webkit-transform: translate(0, 0) !important;
              transform: translate(0, 0) !important; }
              .main-wrapper .main-header__menu--list li .dropdown .dropdown-menu .dropdown-item {
                padding: 8px 10px !important;
                color: #787878; }
                .main-wrapper .main-header__menu--list li .dropdown .dropdown-menu .dropdown-item i {
                  flex: 0 1 auto;
                  margin-right: 6px; }
                .main-wrapper .main-header__menu--list li .dropdown .dropdown-menu .dropdown-item span {
                  flex: 1 1 auto;
                  font-size: 13px; }
  .main-wrapper .main-content {
    padding: 20px 0; }
    .main-wrapper .main-content .card {
      min-height: 100%; }

